	/*
  	Flaticon icon font: Flaticon
  	Creation date: 01/06/2018 20:22
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
        font-family: Flaticon, sans-serif;
        font-size: 1em;
        font-style: normal;
}

.flaticon-quality:before { content: "\f100"; }
.flaticon-checklist:before { content: "\f101"; }
.flaticon-hierarchy:before { content: "\f102"; }
.flaticon-timeline:before { content: "\f103"; }
.flaticon-trophy:before { content: "\f104"; }
.flaticon-graph:before { content: "\f105"; }
.flaticon-businessman:before { content: "\f106"; }
.flaticon-briefcase:before { content: "\f107"; }
.flaticon-job-search:before { content: "\f108"; }
.flaticon-agenda:before { content: "\f109"; }
.flaticon-circle:before { content: "\f10a"; }
.flaticon-emoji:before { content: "\f10b"; }
.flaticon-dislike:before { content: "\f10c"; }
.flaticon-money:before { content: "\f10d"; }
.flaticon-mute-emoticon-square-face:before { content: "\f10e"; }
.flaticon-filter:before { content: "\f10f"; }
.flaticon-settings-48px:before { content: "\f110"; }
.flaticon-settings-power-48px:before { content: "\f111"; }
